import React, { useState } from "react"
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll';
import Seo from 'gatsby-plugin-wpgraphql-seo';
import Layout from '../components/layout'
import SingleNews from '../components/singleNews'
import Subscribe from '../components/subscribe'

import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import _ from 'lodash'

const removeAtIndex = (arr, index) => {
	const copy = [...arr];
	copy.splice(index, 1);
	return copy;
};

const toggle = (arr, item, getValue = item => item) => {
	const index = arr.findIndex(i => getValue(i) === getValue(item));
	if (index === -1) return [...arr, item];
	return removeAtIndex(arr, index);
};

const NewsPage = ({ data }) => {

	const page = data.wpMainPage;
	const news = data.allWpNews.nodes;

	let keyWordsArr = [];
	news.map((article) => {
		return keyWordsArr.push(article.newsextra.keyWords)
	})

	const keyWordsList = keyWordsArr.toString().split(',');

	const uniqueKeyWordsList = _.uniq(keyWordsList);
	const sortedUniqueKeyWordsList = uniqueKeyWordsList.sort((a, b) => {
		return a.localeCompare(b)
	});

	// const keyWordsOccurence = keyWordsList.reduce(function(occ, i) {
	// 	occ[i] = (occ[i] || 0) + 1;
	// 	return occ;
	//   }, {});

	// const keyWordsOccurenceOrdered = Object.keys(keyWordsOccurence).sort(function(a, b) {
	// 	return keyWordsOccurence[b] - keyWordsOccurence[a];
	// });

	// const sortedNews = news.sort((a, b) => {
	// 	let newsdate1 = a.newsextra.menuOrder == null ? 0 : a.newsextra.menuOrder;
	// 	let newsdate2 = b.newsextra.menuOrder == null ? 0 : b.newsextra.menuOrder;
	// 	return (newsdate2 < newsdate1 ? 1 : 0)
	// })

	const sortedNews = news.sort((a, b) => {
		const dateA = new Date(a.newsextra.date);
		const dateB = new Date(b.newsextra.date);
		return dateB - dateA;
	});

	// console.log(sortedNews);
	// const sortedNews = news;

	const latestArticle = sortedNews[0];
	const remainingArticles = _.slice(sortedNews, 1, sortedNews.length);

	const [articles, setArticles] = useState(remainingArticles);
	const [selectedKeyWords, setSelectedKeyWords] = useState([]);
	const KeyWordSelect = (e) => {
		scrollTo('#articlesList');
		const kw = e.target.innerText;
		const tempSKW = toggle(selectedKeyWords, kw);

		const filteredArticles = remainingArticles.filter((art) => {
			if (art.newsextra.keyWords == null) return false;
			const articleKeyWordsArr = art.newsextra.keyWords.split(',').map((item) => item.trim());
			let result = true;
			tempSKW.forEach((tkw) => {
				if (articleKeyWordsArr.indexOf(tkw) < 0) {
					result = false;
				}
			})
			return result;
		})
		setArticles(filteredArticles);
		setSelectedKeyWords(tempSKW);
	}

	const resetTags = () => {
		document.querySelectorAll('span.keyWord.selected').forEach((elem) => {
			elem.classList.remove('selected');
		})
		setArticles(remainingArticles);
		setSelectedKeyWords([]);
	}

	return (
		<Layout title="m1nd-set in the media" breadcrumbs={[['News','#']]}>
			<Seo post={page} />
			<section className="content" id="news">
				<div id="latestArticle">
					<div id="latestArticleImage">
						{latestArticle.featuredImage !== null	?
							<GatsbyImage image={getImage(latestArticle.featuredImage.node.localFile)} alt={latestArticle.title} />
						: ''}
					</div>
					<div id="latestArticleBody">
						<h2>Latest news</h2>
						<SingleNews props={{ newsItem: latestArticle }}/>
					</div>
				</div>
				<div id="articlesSection">
					<ul id="articlesList">
						{articles.length ? articles.map((newsItem, i) => {
							// const image = item.featuredImage !== null ?  : {};
							return (
								<SingleNews key={i} props={{ newsItem, id: `newsItem${i}` }}/>
							)
						})
						: "No article found for the selected keywords."}
					</ul>
					{/* <div id="newsKeyWordCloud">
						<div id="resetTagsWrapper">
							<h2>Tags</h2>
							<button
								id="resetTags"
								onClick={resetTags}
								className="button-general small"
							>Reset</button>
						</div>
						<div id="keyWordList">
							{sortedUniqueKeyWordsList.map((keyWord, i) => {
								if (keyWord === "") return false;
								return (
									<span
										className={`keyWord ${selectedKeyWords.indexOf(keyWord) > -1 ? "selected" : ""}`}
										key={i}
										onClick={KeyWordSelect}
										onKeyDown={KeyWordSelect}
										role="button"
										tabIndex={0}
									>
										{keyWord}
									</span>
								)
							})}
						</div>
					</div> */}
				</div>

			</section>
			<Subscribe/>
		</Layout>
	)
}

export default NewsPage

export const query = graphql`
	query NewsPageQuery {
		allWpNews(limit: 300, sort: {order: ASC, fields: menuOrder}) {
			nodes {
				newsextra {
					date
					keyWords
					link1 {
						link1Label
						link1Url
					}
					link2 {
						link2Label
						link2Url
					}
					link3 {
						link3Label
						link3Url
					}
					link4 {
						link4Label
						link4Url
					}
					link5 {
						link5Label
						link5Url
					}
				}
				title
				content
				featuredImage {
					node {
						sourceUrl
						localFile {
							childImageSharp {
								gatsbyImageData(
									placeholder: NONE
									formats: [AUTO, WEBP, AVIF]
								)
							}
						}
					}
				}
			}
		}
		wpMainPage(databaseId: {eq: 89}) {
			content
			nodeType
			title
			uri
			seo {
				title
				metaDesc
				focuskw
				metaKeywords
				metaRobotsNoindex
				metaRobotsNofollow
				opengraphTitle
				opengraphDescription
				opengraphImage {
					altText
					sourceUrl
					srcSet
				}
				twitterTitle
				twitterDescription
				twitterImage {
					altText
					sourceUrl
					srcSet
				}
				canonical
				cornerstone
				schema {
					articleType
					pageType
					raw
				}
			}
		}
	}
`;
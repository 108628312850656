import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import { faYoutube } from '@fortawesome/free-brands-svg-icons'

const LinkIcon = ({ url }) => {
    return (
        <span className="linkIcon">
            {url.indexOf('youtube') === -1 ? <FontAwesomeIcon icon={faNewspaper}/> : <FontAwesomeIcon icon={faYoutube}/>}
        </span>
    )
}

const groupByLabel = (groupedArray) => {
	return groupedArray.reduce((array, arrayItem) => {
		(array[arrayItem[0]] = array[arrayItem[0]] || []).push(arrayItem[1]);
		return array;
	}, {});
};

const SingleNews = ({ props }) => {

    const newsItem = props.newsItem;

    let newsLinks = [];
    for (let j = 1; j <= 5; j++) {
        if (newsItem.newsextra[`link${j}`][`link${j}Label`] !== null && newsItem.newsextra[`link${j}`][`link${j}Url`]) {
            newsLinks.push(
                [newsItem.newsextra[`link${j}`][`link${j}Label`], newsItem.newsextra[`link${j}`][`link${j}Url`]]
            )
        }
    }

    const groupednewsLinks = groupByLabel(newsLinks);

    return (
        <li className="newsItem" id={props.id} data-keywords={`${newsItem.newsextra.keyWords}`}>
            {newsItem.featuredImage !== null ?
                <GatsbyImage image={getImage(newsItem.featuredImage.node.localFile)} alt="" />
            : ''}
            <p className="date">{newsItem.newsextra.date}</p>
            <div className="newsItemTitleWrapper">
                <h3>{newsItem.title}</h3>
            </div>
            <div className="excerpt" dangerouslySetInnerHTML={{ __html: newsItem.content }}></div>
            <div className="links">
                <h4>More on</h4>
                <div className="linkItems">
                    {Object.keys(groupednewsLinks).map((newspaper, k) => {
                        return (
                            <div className="linkItem" key={k}>
                                <h5>{newspaper}</h5>
                                {groupednewsLinks[newspaper].map((newsLink, l) => {
                                    return (
                                            <a key={l} href={newsLink} target="_blank" rel="noreferrer" aria-label="News link">
                                                <LinkIcon url={newsLink}/>
                                            </a>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            </div>
        </li>
    );
};

export default SingleNews;